import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import es from './es.json';
import ru from './ru.json';

@Injectable({ providedIn: 'root' })
export class DataTableLanguageService {

    constructor(private translate: TranslateService) {}

    // Get the correct datatable language data for the current language.
    getLanguageData() {
        switch (this.translate.getDefaultLang()) {
            case 'ru':
                return ru;
            case 'es':
                return es;
            default:
                return undefined;
        }
    }
}
